const gradients = [
  ["#27278d", "#3c6fa2", "#51b7b7", "#8af0bd"],
  ["#6f096f", "#7e4bb1", "#8d8df3", "#a9d4ff"],
  ["#6f096f", "#a74174", "#e07a7a", "#f5c28f"],
  ["#004f27", "#298f29", "#7eb646", "#b6e676"],
  ["#393900", "#6c7500", "#a2b000", "#d7e90e"],
  ["#552a00", "#ad4747", "#da74a7", "#ffa8ff"],
];

const themes = [
  ["9bebeb", "6da1df", "6653cb", "501a68"],
  ["f4e4d9", "fd78ad", "038ea8", "2d2a01"],
  ["ffec8d", "32d9ce", "f41c7d", "3f0d68"],
  ["f7ffb7", "a5d145", "2a8037", "001b27"],
  ["001b27", "97b6ff", "dd02ef", "011f11"],
  ["fff5de", "fd9785", "f60983", "15017a"],
  ["c5fbe1", "c6aa35", "af5314", "3c1e15"],
  ["d4f1f0", "a1b467", "7f6f01", "2c2517"],
  ["2c2517", "90adbb", "56689d", "262338"],
];
const colorless = ["ffffff", "ffffff", "ffffff", "ffffff"];

const templates = [
  `

##HAIR##6
| ##EYEBROW##1   ##EYEBROW##1 |
 (  ##EYE1##1   ##EYE2##1  ) 
<(   ##NOSE##1   )>
\\  ##MOUTH##3  /
\\ˍˍˍˍ/
`,
  `

##HAIR##7
#       #
d| ##EYE1##1   ##EYE2##1 |b
(  ##NOSE##1  )
) ##MOUTH##3 (
(_____)
`,
  `

##HAIR##7
{       }
d| ##EYE1##1   ##EYE2##1 |b
\\  ##NOSE##1  /
{ ##MOUTH##3 }
└~~~┘
`,
  `

##HAIR##7
║ ##EYEBROW##1   ##EYEBROW##1 ║
d║ ##EYE1##1   ##EYE2##1 ║b
║  ##NOSE##1  ║
╚╗ ##MOUTH##3 ╔╝
╚═════╝
`,
  `

┌##HAIR##7┐
!       !
«│ ##EYE1##1   ##EYE2##1 │»
\\  ##NOSE##1  /
├ ##MOUTH##3 ┤
\`───\´
`,
];

const eyeOpt = {
  o: 100,
  O: 95,
  0: 90,
  p: 85,
  P: 80,
  q: 75,
  "°": 70,
  Q: 65,
  Ö: 60,
  ö: 55,
  ó: 52,
  Ô: 49,
  "■": 46,
  9: 43,
  Ó: 40,
  Ő: 37,
  ő: 35,
  "○": 30,
  "⊙": 27,
  "╬": 25,
  "♥": 20,
  "¤": 15,
  đ: 10,
};
const noseOpt = {
  " < ": 100,
  " > ": 95,
  " V ": 90,
  " W ": 85,
  " v ": 80,
  " u ": 70,
  " c ": 60,
  " C ": 55,
  " ┴ ": 50,
  " L ": 40,
  " Ł ": 35,
  " └ ": 25,
  " ┘ ": 20,
  " ╚ ": 15,
  " ╝ ": 10,
};
const mouthOpt = { "-": 100, _: 85, "=": 60, "~": 45, "═": 25 };
const hairOpt = {
  _: 100,
  "/": 90,
  "!": 75,
  "%": 60,
  "║": 50,
  "▄": 40,
  "█": 35,
};
const browsOpt = { _: 100, "~": 70, "¬": 30 };
const hats = [
  ["  ┌─────┐  ", "  │     │  ", " ─┴─────┴─ "],
  ["  ┌─────┐  ", "  ├─────┤  ", " ─┴─────┴─ "],
  ["  ┌▄▄▄▄▄┐  ", "  ├─────┤  ", " ─┴─────┴─ "],
  ["  ┌─────┐  ", "  ├─────┤  ", " ─┴▀▀▀▀▀┴─ "],
  ["  ┌▄▄▄▄▄┐  ", "  ├─────┤  ", " ─┴▀▀▀▀▀┴─ "],
  ["  ┌▄▄▄▄▄┐  ", "  ├█████┤  ", " ─┴▀▀▀▀▀┴─ "],
  ["  ┌─────┐  ", "  │     │  ", " ─┴▀▀▀▀▀┴─ "],
  ["           ", "  ┌─────┐  ", " ─┴─────┴─ "],
  ["           ", "  ┌─────┐  ", " ─┴▀▀▀▀▀┴─ "],
  ["           ", "   /███   ", " ─┴▀▀▀▀▀┴─ "],
  ["           ", "   /▓▓▓   ", " ─┴▀▀▀▀▀┴─ "],
  ["           ", "   ┌───┐   ", "└─┴─────┴──"],
  ["         ,/", "   ┌───┐/' ", "└─┴─────┴──"],
  ["           ", "   .▄▄▄.   ", "└─┴▀▀▀▀▀┴──"],
  ["         ,/", "   .▄▄▄./' ", "└─┴▀▀▀▀▀┴──"],
  ["           ", "   /ˇˇˇ   ", "  ┴─────┴  "],
  ["  ┌─────┐  ", " ┌┴─────┴┐ ", " └───────┘ "],
  ["           ", "  ┌─────┐  ", " |░░░░░░░| "],
  ["   ,.O.,   ", "  /»»»»»  ", " /««««««« "],
  ["   ,.O.,   ", "  /AAAAA  ", " /VVVVVVV "],
  ["   ,.O.,   ", "  /WWWWW  ", " /MMMMMMM "],
];
const glasses = [
  "-O---O-",
  "-O-_-O-",
  "-┴┴-┴┴-",
  "-┬┬-┬┬-",
  "-▄---▄-",
  "-▄-_-▄-",
  "-▀---▀-",
  "-▀-_-▀-",
  "-█---█-",
  "-█-_-█-",
  "-▓---▓-",
  "-▓-_-▓-",
  "-▒---▒-",
  "-▒-_-▒-",
  "-░---░-",
  "-░-_-░-",
];

function Face(element) {
  this.element = element;
  this.rawTemplate = 0;
  this.theme = 0;
  this.brow = 0;
  this.eye1 = 0;
  this.eye2 = 0;
  this.nose = 0;
  this.mouth = 0;
  this.hair = 0;
  this.showHat = false;
  this.hat = 0;
  this.showGlasses = false;
  this.glasses = 0;
  this.eyeCol = 0;
  this.browCol = 0;
  this.noseCol = 0;
  this.mouthCol = 0;
  this.hairCol = 0;
  this.defaultCol = 0;
  // Animations
  this.blinking = false;
  this.winking = false;
  this.noseShrugging = false;
  this.nodding = false;
  this.textAnimation = [];
  // Show gradient background
  this.gradientBackground = false;
  this.data = [];

  this.timer = null;

  this.init();
}

Face.prototype.random = function (arr) {
  let idx = Math.floor(Math.random() * arr.length);
  return arr[idx];
};

Face.prototype.randomRange = function (val1, val2) {
  if (val2 < val1) {
    let temp = val2;
    val2 = val1;
    val1 = temp;
  }

  return Math.floor(Math.random() * (val2 - val1) + val1);
};

Face.prototype.selectOption = function (options) {
  var keys = Object.keys(options);
  let idx = Math.floor(Math.random() * keys.length);
  return keys[idx];
};

Face.prototype.init = function () {
  this.rawTemplate = this.random(templates);
  this.theme = this.random(themes);
  if (Math.random() <= 1 / 3) this.theme = colorless;

  var invert = this.random([true, false]);

  this.brow = this.selectOption(browsOpt);
  this.eye1 = this.selectOption(eyeOpt);
  this.eye2 = this.eye1;
  this.nose = this.selectOption(noseOpt);
  this.mouth = this.selectOption(mouthOpt);
  this.hair = this.selectOption(hairOpt);

  this.showHat = this.random([true, false, false]);
  this.hat = this.random(hats);

  this.showGlasses = this.random([true, false, false]);
  this.glasses = this.random(glasses);

  if (invert) {
    this.eyeCol = this.theme[2];
    this.browCol = this.theme[2];
    this.noseCol = this.theme[1];
    this.mouthCol = this.theme[0];
    this.hairCol = this.theme[3];
  } else {
    this.eyeCol = this.theme[1];
    this.browCol = this.theme[1];
    this.noseCol = this.theme[2];
    this.mouthCol = this.theme[3];
    this.hairCol = this.theme[0];
  }
  this.defaultCol = this.random(this.theme);

  // Animations
  this.blinking = false;
  this.winking = false;
  this.noseShrugging = false;
  this.nodding = false;

  this.textAnimation = [];

  // Show gradient background
  this.gradientBackground = this.random([true, false, false]);
};

Face.prototype.smartReplace = function (t, key, col, str) {
  while (t.indexOf(key) != -1) {
    var i = t.indexOf(key);
    var count = t.substr(i + key.length, 1);
    if (this.gradientBackground)
      t = t.replace(key + count, `${str.repeat(count)}`);
    else
      t = t.replace(
        key + count,
        `<span style='color:#${col}'>${str.repeat(count)}</span>`
      );
  }
  return t;
};

Face.prototype.makeFace = function () {
  var t = this.rawTemplate;
  t = this.smartReplace(t, "##HAIR##", this.hairCol, this.hair);
  t = this.smartReplace(t, "##EYEBROW##", this.browCol, this.brow);
  t = this.smartReplace(t, "##EYE1##", this.eyeCol, this.eye1);
  t = this.smartReplace(t, "##EYE2##", this.eyeCol, this.eye2);
  t = this.smartReplace(t, "##NOSE##", this.noseCol, this.nose);
  t = this.smartReplace(t, "##MOUTH##", this.mouthCol, this.mouth);
  t = t.split("\n");
  // console.log(t)

  if (this.showHat) {
    t[0] = this.hat[0];
    t[1] = this.hat[1];
    t[2] = this.hat[2];
  }
  if (this.showGlasses) {
    t[4] = t[4].substr(0, 2) + this.glasses + t[4].substr(t[4].length - 2);
  }

  if (this.nodding) {
    t.unshift("");
    t.pop();
  }

  if (this.gradientBackground) {
    t[0] =
      `<div style='background: -webkit-linear-gradient(#${this.theme[0]},#${this.theme[1]},#${this.theme[2]},#${this.theme[3]});-webkit-background-clip: text; -webkit-text-fill-color: transparent;'>` +
      t[0];
    t[t.length - 1] = t[t.length - 1] + `</div>`;
  } else {
    t[0] = `<div style='color:#${this.defaultCol}'>` + t[0];
    t[t.length - 1] = t[t.length - 1] + `</div>`;
  }

  this.data = t;
};

Face.prototype.blink = function () {
  var orig = this.eye1;
  this.eye1 = "_";
  this.eye2 = "_";

  this.blinking = true;

  let _this = this;
  setTimeout(function () {
    _this.eye1 = orig;
    _this.eye2 = orig;
    _this.blinking = false;
    _this.render();
  }, this.randomRange(300, 700));
};

Face.prototype.wink = function () {
  var orig = this.eye1;
  this.eye1 = "_";

  this.winking = true;

  let _this = this;
  setTimeout(function () {
    _this.eye1 = orig;
    _this.winking = false;
    _this.render();
  }, this.randomRange(300, 700));
};

Face.prototype.shrugNose = function () {
  var orig = this.nose;
  this.nose = " " + this.nose.substring(0, 2);

  this.noseShrugging = true;

  let _this = this;
  setTimeout(function () {
    _this.nose = orig;
    _this.noseShrugging = false;
    _this.render();
  }, this.randomRange(700, 1300));
};

Face.prototype.nod = function (amount) {
  if (amount > 0) {
    this.nodding = true;
    let _this = this;
    setTimeout(function () {
      _this.nodding = false;
      _this.render();
      setTimeout(function () {
        _this.nod(amount - 1);
        _this.render();
      }, _this.randomRange(200, 300));
    }, this.randomRange(200, 300));
  }
};

Face.prototype.animateFrame = function () {
  let animation = this.randomRange(0, 5);

  switch (animation) {
    case 0:
      if (!this.blinking && !this.winking) this.blink();
      break;
    case 1:
      if (!this.blinking && !this.winking) this.wink();
      break;
    case 2:
      if (!this.noseShrugging) this.shrugNose();
      break;
    case 3:
      this.nod(Math.ceil(this.randomRange(1, 3)));
      break;
    default:
      break;
  }
};

Face.prototype.animateStart = function () {
  this.animateFrame();
  this.render();
  this.frame++;

  let _this = this;
  this.timer = setTimeout(function () {
    _this.animateStart();
  }, this.randomRange(1000, 3000));
};

Face.prototype.render = function () {
  this.makeFace();

  let innerVal = "";
  for (var i = 0; i < this.data.length; i++) {
    innerVal = innerVal + this.data[i] + "\n";
  }
  this.element.innerHTML = innerVal;
};

Face.prototype.animate = function () {
  this.frame = 0;
  if (this.timer) {
    clearTimeout(this.timer);
  }

  this.render();
  let _this = this;

  this.timer = setTimeout(function () {
    _this.animateStart();
  }, 2000);
};

window.onload = function () {
  var elements = document.getElementsByClassName("face-section");

  for (let i = 0; i < elements.length; i++) {
    let face = new Face(elements[i]);
    face.animate();
  }
};
