import { Contract } from "@ethersproject/contracts";

// account is not optional
export function getSigner(library, account) {
  return library.getSigner(account);
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}
export const getContract = (ABI, address, library, account) => {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
};

export function getContractaddress(chainId) {
  if (chainId === 1) {
    return "0x6E2148A0A47131897431a14bbfd36A6de997266f";
  } else if (chainId === 5) {
    return "0x7b2b142692df343d81584645eCa1F636ef383359";
  }
}
