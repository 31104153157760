import "./App.css";
import "./js/face";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import Mint from "./components/MintComponent";

//this will give instance of library we want ether.js or web3.js i prefer use ether.js
function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div className="container mx-auto">
        <section className="justify-items-center grid-cols-1 md:grid-cols-4">
          <pre className="face-section">─┴▀▀▀▀▀┴─</pre>
          <pre className="face-section">─┴▀▀▀▀▀┴─</pre>
          <pre className="face-section">─┴▀▀▀▀▀┴─</pre>
          <pre className="face-section">─┴▀▀▀▀▀┴─</pre>
        </section>
        <div className="grid md:grid-cols-4  grid-cols-1 w-full justify-center justify-items-center">
          <pre className="face-section">─┴▀▀▀▀▀┴─</pre>
          <Mint />
          <pre className="face-section">─┴▀▀▀▀▀┴─</pre>
        </div>
        <section className="justify-items-center grid-cols-1 md:grid-cols-4">
          <pre className="face-section">─┴▀▀▀▀▀┴─</pre>
          <pre className="face-section">─┴▀▀▀▀▀┴─</pre>
          <pre className="face-section">─┴▀▀▀▀▀┴─</pre>
          <pre className="face-section">─┴▀▀▀▀▀┴─</pre>
        </section>
      </div>
    </Web3ReactProvider>
  );
}

export default App;
