import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { metaMask } from "../connectors";
import {
  useContract,
  useEagerConnect,
  useInactiveListener,
} from "../hooks/connect";

const Mint = (props) => {
  const {
    connector,
    library,
    account,
    chainId,
    activate,
    deactivate,
    active,
    error,
  } = useWeb3React();

  const contract = useContract();

  console.log("contract", contract);
  console.log("active", active);

  const maxClaim = "100";

  // the variable gets populated with the wallet address if connected
  let address = "address";

  // checks if the user is on an wrong network
  const isWrongNetwork = false;

  const switchNetwork = () => {
    console.log("Switch network");
  };

  // sucess and error messages
  const [succes, setSucces] = useState("");
  const [errormsg, setError] = useState("");

  const [isMinting, setIsMinting] = useState(false);

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager);

  useEffect(() => {
    console.log(maxClaim);
  }, [maxClaim]);

  // the qty variable gets populated with the qty which user enters
  const [qty, setQty] = useState(1);

  // mint function
  const mint = async () => {
    if (!active) {
      activate(metaMask);
      address = account;
      return;
    }
    // if (!library) return;
    setError("");
    setSucces("");

    if (!activate) {
      setError("Please connect your wallet.");
      return;
    }

    if (isWrongNetwork) {
      switchNetwork && switchNetwork(props.activeChainId);
      return;
    }

    if (qty < 1) {
      setError(`Min 1 required.`);
      return;
    }

    if (qty > Number(maxClaim)) {
      setError(`Max ${maxClaim} allowed`);
      return;
    }

    let supply = Number(String(await contract.totalSupply()));
    let overrides;
    console.log(supply);
    if (supply + qty > 1000) {
      overrides = {
        value: String(qty * 3200000000000000),
      };
    } else {
      overrides = {
        value: 0,
      };
    }

    try {
      let tx = await contract.mint(qty, overrides);
      if (await tx.wait()) {
        setIsMinting(true);
      }
      setIsMinting(false);
      setSucces("Mint successful 🥳");
      setQty(1);
    } catch (error) {
      // console.log(qty);
      setIsMinting(false);
      setQty(1);
      setError(error.reason);
    }
  };

  const claimedSupply = "10";
  const unclaimedSupply = "8";

  return (
    <>
      <div className="flex w-full md:col-span-2 min-h-max justify-center items-center">
        <div className="mint__card w-[20rem] border-2 border-white border-dotted rounded-lg p-4">
          <div className="card__top flex flex-col  justify-between items-center">
            <h2 className="heading font-bold  text-xl">Chainmortal</h2>
            <span className="text-neutral-400 text-sm	">
              Free mint 
            </span>
          </div>

          <div className="card__btn mt-3">
            {address ? (
              <div className="mint__options flex justify-centre items-centre gap-x-2">
                <input
                  type="number"
                  placeholder="QTY"
                  name="qty"
                  className="border text-black text-sm rounded-md block w-[30%] h-full p-2.5 bg-gray-700 border-black focus:outline-none focus:border-[#5d00ff] focus:ring-1 focus:ring-[#5d00ff]"
                  disabled={isMinting}
                  min="1"
                  max={maxClaim}
                  onChange={(e) => {
                    setQty(Number(e.target.value));
                  }}
                />

                <button
                  onClick={mint}
                  disabled={isMinting}
                  className="p-2 bg-[#5d00ff] rounded-md w-[70%] text-white heading"
                >
                  {!active
                    ? "Connect wallet"
                    : isMinting
                    ? "Minting"
                    : isWrongNetwork
                    ? "Change network"
                    : "Mint"}
                </button>
              </div>
            ) : (
              <button className="p-2 bg-[#5d00ff] rounded-md w-[100%] text-white heading">
                Connect Wallet
              </button>
            )}

            <span className="loader-mint"></span>
          </div>
        </div>
      </div>
      {succes ? (
        <div
          className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg fixed bottom-0 right-4"
          role="alert"
        >
          <span className="font-medium">{succes}</span>
        </div>
      ) : errormsg ? (
        <div
          className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg fixed bottom-0 right-4"
          role="alert"
        >
          <span className="font-medium"> {errormsg} </span>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Mint;
